<template>
  <div class="store">
    <iframe :src="src" frameborder="0"></iframe>
  </div>
</template>

<script>
// 导入底部导航栏组件
export default {
  components: {},
  data() {
    return {
      src: "",
    };
  },
  created() {
    this.add();
  },
  methods: {
    add() {
      let params = new FormData();
      let abbreviation = JSON.parse(
        sessionStorage.getItem("abbreviation")
      ).travelAbbreviation;
      params.append("abbreviation", abbreviation);
      this.$axios.post("/api/msg/getShopping", params).then((res) => {
        this.src = res.data.shoppingMall;
      });
    },
  },
};
</script>

<style lang="less" scoped>
iframe {
  width: 100vw;
  height: 92vh;
}
</style>